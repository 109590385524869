$colours: (
  green: #30AF5F,
  olive: #A0CC3A,
  purple:#4E59A3,
  blue: #37A9E0,
  orange:#E67F25,
  grey: #4C494B,
  white: #ffffff
);

$light-ui: (
  text-lighter: #948f93,
  border: #e4dee3,
  border-lighter: #f0f0f0,
  text: #4C494B,
  bg: #F8F8F8,
  container: #ffffff,
  container-transparent: #fffffff7
);

:root {
  @each $name, $colour in $colours {
    --theme-#{$name}: #{$colour};
  }
  @each $name, $colour in $light-ui {
    --ui-#{$name}: #{$colour};
  }
}

$font-primary:"Gotham SSm A", "Gotham SSm B", sans-serif;
$font-secondary:"Roboto", sans-serif;
