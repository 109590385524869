@import "categories/variables.scss";
@import "categories/typography.scss";
@import "categories/form.scss";
@import "categories/animations.scss";
@import "categories/colouring.scss";
@import "categories/layouts.scss";

body {
    margin: 0;
    background-color: var(--ui-bg);
  }
  
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
.explorer-container {
  background-color: var(--ui-container);
  box-shadow: 0 2px 5px rgba(0,0,0,.1);
  height: 100vh;
  display: grid;
  grid-template-rows: 70px calc(100vh - 98px) 28px;
  .header-bar {
    border-bottom: 1px solid var(--ui-border);
    padding: 0px 20px;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    img {
      height: 32px;
    }
    form {
      justify-self: flex-end;
      display: grid;
      grid-template-columns: auto auto 40px;
      gap: 10px;
    }
  }
  .results {
    .unauthorised {
      text-align: center;
      height: 100%;
      display: grid;
      align-content: center;
      justify-items: center;
      i {
        font-size: 24px;
      }
    }
    .authorised {
      display: grid;
      grid-template-columns: 300px auto;
      overflow: scroll;
      height:100%;
      .filter-bar {
        border-right: 1px solid var(--ui-border);
        padding: 20px;
        overflow: scroll;
      }
      .data {
        overflow: scroll;
      }
    }
  }
  .footer-bar {
    background-color: #2B2B2B;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    padding: 0px 12.5px;
    p, a {
      color: white;
      text-decoration: none;
      font-family: sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 10px;
    }
    .status {
      justify-self: flex-end;
      display: flex;
      .status-circle {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: map-get($colours, orange);
      }
      p {
        margin-left: 5px;
      }
    }
  }
}