.transition {
  @for $i from 1 to 10 {
    &-#{$i} {
      transition: 0s + $i / 10 linear;
    }
  }
}
.duration {
  @for $i from 1 to 10 {
    &-#{$i} {
      animation-duration: 0s + $i / 10!important;
    }
  }
}

.animation-delay {
  @for $i from 1 to 30 {
    &-#{$i} {
      animation-delay: 0s + $i / 10;
    }
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.display {
  visibility: visible;
  opacity: 1;
}


.opacity {
  @for $i from 1 to 101 {
    &-#{$i} { opacity: 0.01 * $i };
  }
}

.fade-in {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 800px){
  .mobile-hide {
    display: none!important;
  }
  
  .mobile-show {
    display: flex!important;
  }
}