@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://green-cdn.fra1.digitaloceanspaces.com/fonts/751832/0EE16F2042BD93429.css');

.ui {
  &-title {
    color: var(--ui-text);
    font-family: $font-primary;
    font-weight: 500;
    letter-spacing: -.045em;
    &-mega {
      @extend .ui-title;
      font-size: 3em;
      @media only screen and (max-width: 600px){
        font-size: 1.5em;
      }
    }
    &-one {
      @extend .ui-title;
      font-size: 2.25em;
      @media only screen and (max-width: 600px){
        font-size: 1.3em;
      }
    }
    &-two {
      @extend .ui-title;
      font-size: 1.75em;
      @media only screen and (max-width: 600px){
        font-size: 1.2em;
      }
    }
    &-three {
      @extend .ui-title;
      font-size: 1.5em;
      @media only screen and (max-width: 600px){
        font-size: 1.1em;
      }
    }
    &-four {
      @extend .ui-title;
      font-size: 1.25em;
      @media only screen and (max-width: 600px){
        font-size: 1em;
      }
    }
    &-five {
      @extend .ui-title;
      font-size: 1.1em;
      @media only screen and (max-width: 600px){
        font-size: .9em;
      }
    }
    &-sub {
      @extend .ui-title;
      color: var(--ui-text-lighter);
      font-weight: 300;
    }
  }
  &-label {
    display: block;
    color: var(--ui-text);
    font-family: $font-secondary;
    font-size: 1em;
    font-weight: 500;
  }
  &-disclaimer {
    color: var(--ui-text);
    font-family: $font-primary;
    opacity: .6;
    font-size: .8em;
    font-weight: 400;
    line-height: 1.6;
    text-decoration: none;
  }
  &-body {
    color: var(--ui-text);
    font-family: $font-primary;
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.6;
  }
  &-link {
    color: map-get($colours, blue);
    font-family: $font-secondary;
    text-decoration: none;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    // font-size: 18px;
    &:after {
      content: '';
      position: absolute;
      width: 0; height: 1.5px;
      display: block;
      right: 0;
      background: rgba(map-get($colours, blue),.2);
      transition: width .4s ease;
    }
    &:hover:after {
      width: 100%;
      left: 0;
    }
  }
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

.text-center {
  text-align: center;
}